import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import Image from "../../ImageQuerys/RandomImages";
import NewVideoStyles from "./NewVideoStyles";
import BackgroundSection from "./VideoPosterImage";
import play from "../../../images/svg/play-blue.svg";

const container = {
  show: {
    transition: {
      staggerChildren: 0.35,
      delay: 1,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: 200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  },
};

const video = {
  hidden: {
    opacity: 0,
    y: 100,
    scale: 1.2,
  },
  show: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      delay: 2,
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};

const VideoFull = (props) => {
  const { className, sectionData, bgDark } = props;
  const [playing, setPlaying] = useState(false);
  const [videoComponent, setVideoComponent] = useState(null);
  const controls = useAnimation();
  const titleControls = useAnimation();
  const { ref, inView } = useInView();

  const opts = {
    height: "690",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const endPlaying = () => {
    setPlaying(false);
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  };
  const handleVideo = () => {
    setPlaying(true);
  };

  useEffect(() => {
    if (!isMobileOnly) {
      if (inView) {
        controls.start("show");
        titleControls.start("show");
      }
      if (!inView) {
        controls.start("exit");
        titleControls.start("exit");
      }
    }
  }, [controls, inView, titleControls]);

  useEffect(() => {
    const vid = (
      <YouTube
        videoId={sectionData.videoURL}
        opts={opts}
        onReady={onReady}
        onEnd={endPlaying}
        className={`video-cont rounded-large block `}
      />
    );
    setVideoComponent(vid);
  }, []);

  return (
    <section
      className={`${className} about-full relative m-0 w-full overflow-hidden z-0 ${
        bgDark ? "bg-black" : "bg-white"
      }`}
    >
      <Image
        imageName="half-elipse.png"
        style={{ zIndex: -1, position: "absolute" }}
        className="absolute top-1/4 w-[280px] lg:w-[400px] max-w-[400px]"
      />
      <Image
        imageName="utrun.png"
        className="absolute bottom-0 right-0 w-[120px] lg:w-[250px] max-w-[250px] z-[1]"
        style={{ zIndex: -1, position: "absolute" }}
      />
      <div className="grid grid-cols-1 max-w-1440 pb-[100px] lg:pb-[204px] pt-[124px] border-box">
        <div ref={ref}>
          <motion.div
            variants={container}
            initial={isMobileOnly ? "show" : "hidden"}
            animate={controls}
            exit="exit"
          >
            <motion.h2
              variants={item}
              className="text-white lg:text-h1 text-h1-small text-center font-bold mb-10"
            >
              {sectionData.title}
              <span className="text-primary">.</span>
            </motion.h2>
            <motion.p
              variants={item}
              className="max-w-[912px] mx-auto text-black-600 text-p lg:text-p-large text-center mb-10 lg:mb-20 px-4 lg:px-0"
            >
              {sectionData.subtext}
            </motion.p>
          </motion.div>
        </div>
        <div className="z-10">
          {playing ? (
            <div className="lg:mx-8">{videoComponent}</div>
          ) : (
            <motion.div
              ref={ref}
              variants={video}
              initial={isMobileOnly ? "show" : "hidden"}
              animate="show"
            >
              <div
                className={` lg:max-h-[690px] max-h-[400px] overflow-hidden mx-4 lg:mx-10 ${
                  !playing ? "block" : "hidden"
                }`}
                style={{ borderRadius: 32 }}
              >
                <BackgroundSection
                  imageName={sectionData.videoPoster}
                  className="rounded-large"
                  style={{ width: "105%" }}
                >
                  <div className="lg:h-[690px] h-[400px] relative">
                    <button
                      type="button"
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[134px] h-[134px] rounded-full border-none bg-primary-30"
                      onClick={handleVideo}
                    >
                      <img src={play} alt="" />
                    </button>
                  </div>
                </BackgroundSection>
              </div>
            </motion.div>
          )}
        </div>
      </div>
      <NewVideoStyles />
    </section>
  );
};

VideoFull.propTypes = {
  className: PropTypes.string,
  sectionData: PropTypes.objectOf(PropTypes.any),
  bgDark: PropTypes.bool,
};
VideoFull.defaultProps = {
  className: "",
  sectionData: {},
  bgDark: true,
};

export default VideoFull;
