import React from "react";
import { viewports, colors } from "../../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller } = viewports;
const { white, black } = colors;

const NewVideoStyles = () => (
  <style jsx>{`
    .about-full .grid {
      width: auto;
      height: auto;
      margin: auto;
      max-height: initial;
      gap: 0;
    }
    .about-full .grid-cols-1 {
      grid-template-columns: 1fr !important;
    }
    h1 {
      white-space: pre;
    }
    .video-cont {
      z-index: 99 !important;
      box-sizing: border-box;
    }
    @media ${mdOrSmaller} {
      h1 {
        padding: 0 16px;
        font-size: 48px;
      }
      .video-p {
        padding: 0 16px !important;
      }
      .video-cont {
        padding: 0 16px !important;
        width: 100% !important;
      }
    }
    .video-detail {
      position: absolute;
      top: 25%;
      width: 400px;
    }
    .video-cont {
      border-radius: 24px;
      width: 100%;
      box-sizing: border-box;
    }
    .video-cont iframe {
      border-radius: 32px;
    }
    .video-cont:before {
      border-radius: 32px;
    }
    .video-cont:after {
      border-radius: 32px;
    }
    .video-pre {
      position: relative;
      min-height: 690px;
    }
    .video-button {
      width: 130px;
      height: 130px;
      border-radius: 120px;
      background: #e8f0ff;
      border: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:hover {
        cursor: pointer;
      }
    }
    .video-button:hover {
      cursor: pointer;
    }
  `}</style>
);

export default NewVideoStyles;
